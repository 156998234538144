.section-manifesto {
  background: $gradient;

  .container {
    max-width: none;
  }

  .logo {
    max-width: 400px;
    fill: $light;

    .afa-logo {
      fill: $dark;
    }
  }

  .manifesto-content {
    @include font-headline;
    @include fluid-font(20px, 50px);
    line-height: 1.2;
    color: $dark;
    text-transform: uppercase;

    @include breakpoint(md) {
      font-size: 3.5vw;
    }

    em {
      background-image: linear-gradient(to bottom, $light 50%, $light 50%);
      background-position: 0 0.9em;
      background-repeat: repeat-x;
      background-size: 2px 6px;
    }

    span:not(.aliens-inline) {
      @include star-bg;
      background-position: center 0.72em;
      color: transparent;
      padding: 0 0.03em;
      margin: 0 0.03em;
    }

    .aliens-inline {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 164 35'%3E%3Cg fill='%23FFF' fill-rule='nonzero'%3E%3Cpath d='M0 32l1.8-.5 9.9-28.7L20 0l10.4 31.5 1.8.5v1.1H16.1V32l3.2-.5-2.8-9.1H6.7l-3 9.1 3.2.5v1.1H0V32zm16.1-11L11.8 7 7.1 21h9zM34.3 32l1.8-.5v-29L34.3 2V.9h13.8V2l-1.8.5v29H54l5-8.2h1.1v9.9H34.3zM62.4 32l1.8-.5v-29L62.4 2V.9h13.8V2l-1.8.5v29l1.8.5v1.1H62.4zM79 32l1.8-.5v-29L79 2.1V.9h25.8v9.2h-1.1l-4-8H91v13.6h3.7l2.5-6.1h1.1v13.3h-1.1l-2.5-6.1H91V32h8.7l3.7-8.5h1.1v9.6H79zM111 4.3v27.2l2.5.5v1.1h-5.8V32l1.8-.5v-29l-1.8-.5V.9h13.7l12.4 18V2.5l-2.5-.5V.9h5.8V2l-1.8.5v30.8h-5zM139.9 31.1v-9.9h1.1l3 6.9c1.8 2.4 3.8 4.5 6.4 4.5 2.8 0 4.6-1.7 4.6-4.2 0-2-.9-3.5-3.4-5.3l-6.8-5c-3.2-2.4-5.1-4.5-5.1-8.4 0-4 2.2-7.9 12.2-9.5 4.6 0 5.4.1 9.6 1.4v9.6h-1.1l-3.6-7.7c-1.2-1.3-2.4-2.1-4.2-2.1-2.2 0-4.2 1.1-4.2 3.8 0 2.2 1.4 3.6 3.5 5.1l6.5 4.8c3.5 2.5 4.8 4.6 4.8 8.4 0 5.4-4 8.8-13.2 10.6-3.4-.1-6.2-.8-10.1-3z'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center calc(50% + 0.06em);
      color: transparent;
      padding: 0 0.5em;
    }
  }
}
