.section-about {
  background: $dark;
  color: $light;

  .richtext {
    max-width: 540px;

    @include breakpoint(md) {
      width: 65%;
    }
  }
}

.about-social {
  margin-top: 64px;

  @include breakpoint(lg) {
    margin-top: 128px;
  }

  ul {
    display: flex;
    margin-top: 8px;
  }

  li {
    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  a {
    @include transition-fast;
    display: block;
    width: 30px;
    height: 30px;

    &:hover {
      color: $accent;
    }
  }
}
