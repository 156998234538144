.section-social {
  background: $gradient;
  text-align: center;

  img {
    width: 50%;
    max-width: 400px;
    margin: auto;
    transform: rotate(-20deg);
  }
}

.social-ctas {
  margin-top: 36px;

  @include breakpoint(xxl) {
    margin-top: 48px;
  }

  .text-headline {
    color: $light;
  }

  ul {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  li {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  a {
    @include transition-fast;
    display: block;
    width: 40px;
    height: 40px;

    @include breakpoint(lg) {
      width: 56px;
      height: 56px;
    }

    &:hover {
      color: $light;
    }
  }
}
