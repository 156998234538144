$breakpoints: (
  'md': 768px,
  'lg': 1024px,
  'xl': 1280px,
  'xxl': 1440px
) !default;

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  @else if $breakpoint == md-only {
    @media (min-width: map-get($breakpoints, md)) and (max-width: map-get($breakpoints, lg) - 1px) {
      @content;
    }
  }
  @else {
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

$fluid-min-width: 320px;
$fluid-max-width: 1440px;

@mixin fluid-font($min-font-size, $max-font-size, $min-vw: $fluid-min-width, $max-vw: $fluid-max-width) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin star-bg {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 53 50' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2'%3E%3Cpath d='M26.286-.001l6.204 19.095h20.083L36.327 30.903l6.204 19.096-16.245-11.805L10.04 50l6.204-19.096L-.002 19.094h20.083L26.286 0z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

@mixin star-bg-accent {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 66 63' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2'%3E%3Cpath d='M32.835.006l7.746 23.841h25.07L45.37 38.585l7.75 23.842-20.284-14.734-20.283 14.734 7.75-23.842L.018 23.847H25.09L32.835.006z' fill='%2300db5f' fill-rule='nonzero'/%3E%3C/svg%3E");
}

@mixin transition-fast {
  transition: all 0.2s ease-in-out;
}
