@font-face {
  font-family: founders-grotesk-condensed;
  src: url(../fonts/founders-grotesk-x-condensed-test-bold.woff) format("woff"),
       url(../fonts/founders-grotesk-x-condensed-test-bold.otf) format("otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: founders-grotesk;
  src: url(../fonts/founders-grotesk-web-regular.woff2) format("woff2"),
       url(../fonts/founders-grotesk-web-regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: founders-grotesk;
  src: url(../fonts/FoundersGrotesk-Bold.otf) format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@mixin font-body {
  font-family: "founders-grotesk", sans-serif;
}

@mixin font-headline {
  font-family: "founders-grotesk-condensed", sans-serif;
}

em {
  font-style: normal;
}

.em-gradient {
  background-image: $gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.em-underline {
  display: inline-block;
  position: relative;

  &:after {
    content: '';
    background-image: $gradient;
    width: calc(100% + 2px);
    height: 5px;
    position: absolute;
    bottom: 0;
    left: -1px;
    z-index: -1;
  }
}

.text-hide {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.text-headline {
  @include font-headline;
  @include fluid-font(32px, 56px);
  text-transform: uppercase;
  line-height: 0.85;
}

.text-dim {
  color: #4d4d4d;
  font-size: 0.8em;
}

.richtext {
  p, ul {
    margin: 16px 0;
  }
}

.styled-list {
  li {
    position: relative;
    padding-left: 1.25em;

    &:before {
      @include star-bg-accent;
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 0.25em;

      @include breakpoint(lg) {
        top: 0.3em;
      }

      @include breakpoint(xxl) {
        top: 0.4em;
      }
    }
  }
}
