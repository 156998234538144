$light: white;
$dark: black;
$accent: #00db5f;
$gradient: linear-gradient(90deg, rgba(0,219,95,1) 0%, rgba(0,219,95,1) 25%, rgba(55,236,213,1) 100%);

@import 'base/mixins';
@import 'base/normalize';
@import 'base/typography';

:root {
  --section-v-offset: 50px;

  @include breakpoint(md) {
    --section-v-offset: 75px;
  }

  @include breakpoint(lg) {
    --section-v-offset: 100px;
  }
}

body {
  @include font-body;
  @include fluid-font(18px, 25px);
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-whatinput="mouse"] {
  *:focus {
    outline: 0;
  }
}

.container {
  width: 80vw;
  max-width: 1200px;
  margin: 0 auto;
}

.section-pad {
  padding-top: var(--section-v-offset);
  padding-bottom: var(--section-v-offset);
}

.star-group {
  img {
    display: block;
    width: 50%;
    max-width: 600px;
    margin: auto;
  }
}

.styled-btn {
  position: relative;
  border-style: solid;
  border-image-slice: 1;
  border-image-source: $gradient;
  border-width: 2px;
  text-transform: uppercase;
  padding: 16px 16px 8px;
  font-weight: bold;
  line-height: 1;

  @include breakpoint(md) {
    padding: 24px 32px 16px;
  }

  &:before {
    @include transition-fast;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    background-image: $gradient;
  }

  &:hover:before {
    opacity: 1;
  }
}

@import 'sections/manifesto';
@import 'sections/can-do';
@import 'sections/cant-do';
@import 'sections/social';
@import 'sections/about';
@import 'sections/modals';
